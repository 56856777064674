<template>
  <b-card title="Voucher">
    <div class="float-left">
      <b-button-group class="action-button">
        <b-dropdown variant="primary" right split text="Aksi" v-if="$can('add', 'vouchers')">
          <b-dropdown-item @click="add()" v-if="$can('add', 'vouchers')" :disabled="isLoadingForm">
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              /><span>Tambah</span>
          </b-dropdown-item>
          <b-dropdown-item @click="addBatch()" v-if="$can('add', 'vouchers')" :disabled="isLoadingForm">
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              /><span>Tambah Batch</span>
          </b-dropdown-item>
          <b-dropdown-item @click="toggleRow()" v-if="$can('edit', 'vouchers')" :disabled="isLoadingForm">
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              /><span>Edit Batch</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-button-group>
    </div>

      <div class="float-left ml-1 voucher-filter">
        <v-select
          v-model="serverParams.status"
          :options="filterValues"
          :reduce="val => val.value"
          :clearable="false"
          input-id="users"
          placeholder="Filter role"
          @input="onFilterChange"
        />
      </div>
    
    <!-- search input -->
    <div class="custom-search float-right">
      <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="serverParams.search"
                placeholder="Ketik kata kunci disini..."
                name="text"
                class="d-inline-block"
              />
              <b-button size="md" variant="" @click="onSearchChange" class="ml-1">
                Cari
              </b-button>
            </div>
          </b-form-group>
    </div>

    <div style="clear:both"></div>

    <div class="mb-1">
      <div class="action-button mr-1" v-if="selectOptions.enabled && checkedRows.length > 0">
        <b-button size="md" variant="outline-warning" @click="toggleStatus()" :disabled="isLoadingForm">
          <span>{{ (!isLoadingForm) ? toggleButtonTitle : "Tunggu..." }}</span>
        </b-button>
      </div>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
      :select-options="selectOptions"
      @on-selected-rows-change="onSelectionChanged"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
        <span class="text-nowrap">{{ (serverParams.page == 1) ? (props.row.originalIndex + 1) : ((props.row.originalIndex + 1) + ((serverParams.page - 1) * serverParams.perPage)) }}</span>
        </span>

        <!-- Column: Voucher Code -->
        <span
          v-if="props.column.field === 'voucher_code'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Voucher Category -->
        <span
          v-if="props.column.field === 'voucher_category_formatted'"
          class="text-nowrap"
        >
          <div>{{ (props.row.voucher_category) ? props.row.voucher_category : '-' }}</div>
        </span>

        <!-- Column: Voucher Name -->
        <span
          v-if="props.column.field === 'voucher_value_amount'"
          class="text-nowrap"
        >
          <div>{{ formatPrice(props.row.voucher_value) }}</div>
        </span>

        <!-- Column: Sold Status -->
        <span
          v-if="props.column.field === 'sold_status_label'"
          class="text-nowrap"
        >
          <p>
            <b-badge :variant="statusVariant(props.row.sold_status == 1)">
                    {{ (props.row.sold_status == 1) ? 'Terjual' : 'Belum Terjual'  }}
                </b-badge>
          </p>
        </span>

        <!-- Column: Used Status -->
        <span
          v-if="props.column.field === 'used_status_label'"
          class="text-nowrap"
        >
        <p>
            <b-badge :variant="statusVariant(props.row.used_status == 1)">
                    {{ (props.row.used_status == 1) ? 'Dipakai' : 'Belum Dipakai'  }}
                </b-badge>
          </p>
        </span>

        <!-- Column: Created by -->
        <span
          v-if="props.column.field === 'used_by_user'"
          class="text-nowrap"
        >
          <span class="text-nowrap" v-if="props.row.used_by !== null">{{ props.row.used_by.name }}</span>
        </span>

        <!-- Column: Updated at -->
        <span
          v-if="props.column.field === 'updated_at'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Created by -->
        <span
          v-if="props.column.field === 'updated_by_user'"
          class="text-nowrap"
        >
          <span class="text-nowrap" v-if="props.row.updated_by !== null">{{ props.row.updated_by.name }}</span>
          <span class="text-nowrap" v-if="props.row.created_by !== null && props.row.updated_by === null">{{ props.row.created_by.name }}</span>
          <span class="text-nowrap" v-if="props.row.created_by === null && props.row.updated_by === null">-</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="editModal(props.row.id)"  v-if="$can('edit', 'vouchers')">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Ubah</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row.id)"  v-if="$can('delete', 'vouchers')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Hapus</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Data 1 hingga
            </span>
            <b-form-select
              v-model="serverParams.perPage"
              :options="['3','5','10', '25', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> dari {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              v-model="serverParams.page"
              :value="1"
              :total-rows="totalRecords"
              :per-page="serverParams.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage: value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  <!-- Modals -->
    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showFormModal"
    >
      <template #modal-header="{}">
        <h5>{{ (isEdit) ? "Ubah" : "Tambah" }} {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Tunggu..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm" @submit.prevent="save()">
        <b-form-group>
          <label for="name">Nama:</label>
          <b-form-input
            id="name"
            name="text"
            v-model="currentObject.voucher_name"
            placeholder="Nama Voucher"
          />
        </b-form-group>

        <b-form-group>
          <label for="name">Kode:</label>
          <b-form-input
            id="code"
            name="text"
            v-model="currentObject.voucher_code"
            placeholder="Kode Voucher"
          />
        </b-form-group>

        <b-form-group>
          <label for="name">Tagging:</label>
          <b-form-input
            id="category"
            name="text"
            v-model="currentObject.voucher_category"
            placeholder="Tagging Voucher"
          />
        </b-form-group>

        <b-form-group>
          <label for="name">Nilai (Rp):</label>
          <b-form-input
            id="value"
            name="number"
            v-model="currentObject.voucher_value"
            placeholder="Nilai voucher"
          />
        </b-form-group>

      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="primary" @click="save()" :disabled="!isValid() || isLoadingForm">
          {{ (!isLoadingForm) ? "Simpan" : "Tunggu..." }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-batch-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showFormBatchModal"
    >
      <template #modal-header="{}">
        <h5>{{ "Tambah Batch " + pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Tunggu..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm" @submit.prevent="save()">
        <b-form-group>
          <label for="name">Nama:</label>
          <b-form-input
            id="name"
            name="text"
            v-model="currentObject.voucher_name"
            placeholder="Nama Voucher"
          />
        </b-form-group>

        <b-form-group>
          <label for="name">Prefix:</label>
          <b-form-input
            id="prefix"
            name="text"
            v-model="currentObject.voucher_prefix"
            placeholder="Prefix Voucher (maks 4 karakter)"
          />
        </b-form-group>

        <b-form-group>
          <label for="name">Kuantitas:</label>
          <b-form-input
            id="qty"
            name="number"
            max="1000"
            min="0"
            v-model="currentObject.voucher_qty"
            placeholder="Jumlah voucher yang akan dibuat"
          />
        </b-form-group>

        <b-form-group>
          <label for="name">Tagging:</label>
          <b-form-input
            id="category"
            name="text"
            v-model="currentObject.voucher_category"
            placeholder="Tagging Voucher"
          />
        </b-form-group>

        <b-form-group>
          <label for="name">Nilai (Rp):</label>
          <b-form-input
            id="value"
            name="number"
            v-model="currentObject.voucher_value"
            placeholder="Nilai voucher"
          />
        </b-form-group>

      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="primary" @click="saveBatch()" :disabled="!isValidBatch() || isLoadingForm">
          {{ (!isLoadingForm) ? "Simpan" : "Tunggu..." }}
        </b-button>
      </template>


    </b-modal>

  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Hapus {{ pageName }}</h5>
      </template>

      <p>
        Apakah anda yakin untuk menghapus data ini?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Tunggu..." : "Hapus" }}
        </b-button>
      </template>


    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BBadge, BButtonGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    BBadge,
    BButtonGroup,
    vSelect,
  },
  data() {
    return {
      filterValues: [
        {
          label: "Semua Voucher",
          value: "all"
        },
        {
          label: "Terjual",
          value: "sold"
        },
        {
          label: "Belum Terjual",
          value: "unsold"
        },
        {
          label: "Dipakai",
          value: "used"
        },
        {
          label: "Belum Dipakai",
          value: "unused"
        },
        {
          label: "Belum Dijual & Belum Dipakai",
          value: "unsold_unused"
        },
        {
          label: "Sudah Dijual & Dipakai",
          value: "sold_used"
        }
      ],
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number',
        },
        {
          label: 'Nama',
          field: 'voucher_name',
        },
        {
          label: 'Kode',
          field: 'voucher_code',
        },
        {
          label: 'Tagging',
          field: 'voucher_category_formatted',
        },
        {
          label: 'Nilai',
          field: 'voucher_value_amount',
        },
        {
          label: 'Status Penjualan',
          field: 'sold_status_label',
        },
        {
          label: 'Status Pemakaian',
          field: 'used_status_label',
        },
        {
          label: 'Dipakai Oleh',
          field: 'used_by_user',
        },
        {
          label: 'Tgl Perbaharuan',
          field: 'updated_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Diperbaharui Oleh',
          field: 'updated_by_user',
        },
        {
          label: 'Aksi',
          field: 'action',
        },
      ],
      rows: [],
      allRows: [],

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      formState: "Edit",
      isEdit: false,
      showFormModal: false,
      showFormBatchModal: false,
      showDeleteModal: false,
      pageName: "Voucher",

      currentObject: {
        id: -1,
        voucher_name: '',
        voucher_code: '',
        voucher_value: 0,
        voucher_category: '',
        voucher_qty: 0,
        voucher_prefix: '',
      },
      totalRecords: 0,
      serverParams: {
        search: null,
        status: "all",
        columnFilters: {},
        sortBy: null,
        sortType: null,
        page: 1, 
        perPage: 10
      },
      checkedRows: [],
      selectOptions: {
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      },
      toggleButtonTitle: "Tandai Terjual",
    }
  },
  computed: {
  },
  methods: {
    toggleStatus() {
      this.isLoadingForm = true

      let ids = []

      this.checkedRows.forEach(row => {
        ids.push(row.id)
      })

      this.$http.post('/admin/v1/vouchers_toggle_status', {ids: ids})
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sukses`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

            this.loadItems()
          }

          this.isLoadingForm = false

          this.checkedRows = []
          this.selectOptions.enabled = false
      })
    },
    toggleRow() {
      this.selectOptions.enabled = !this.selectOptions.enabled
    },
    onSelectionChanged(value) {
      this.checkedRows = value.selectedRows

      let contains_sold = false
      let contains_unsold = false

      this.checkedRows.forEach(row => {
        if(row.sold_status == 1) {
          contains_sold = true
        }
        else {
          contains_unsold = true
        }
      })

      if(contains_sold && contains_unsold) {
        this.toggleButtonTitle = "Tandai Terjual & Tidak Terjual"
      }
      else 
      if(contains_sold && !contains_unsold) {
        this.toggleButtonTitle = "Tandai Tidak Terjual"
      }
      else {
        this.toggleButtonTitle = "Tandai Terjual"
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    onFilterChange(value) {
      this.updateParams({status: value})
      this.loadItems()
    },
    onSearchChange() {
      if(this.serverParams.search.length > 0) {
        this.updateParams({search: this.serverParams.search})
      }
      else {
        this.updateParams({search: null})
      }
      
      this.loadItems()
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage})
      this.loadItems()

    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage})
      this.loadItems()
    },

    onSortChange(params) {
      if(params[0].field == "action") {
        return
      }

      if(this.serverParams.sortBy == params[0].field) {
        if(params[0].type == "asc" && params[0].type == this.serverParams.sortType) {
          this.updateParams({sortType: "desc"})
        }
        else 
        if(params[0].type == "desc" && params[0].type == this.serverParams.sortType){
          this.updateParams({sortType: "asc"})
        }
      }
      else {
        this.updateParams({sortBy: params[0].field})
        this.updateParams({sortType: params[0].type})
      }

      this.loadItems()
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems()
    },

    loadItems() {
      this.isLoadingTable = true
      this.fetchData(this.serverParams).then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.allRows = currentData.rows
            this.rows = currentData.rows
            this.totalRecords = currentData.total
          }


          this.isLoadingTable = false
     })
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    statusVariant(status) {
      return status ? 'light-success' : 'light-danger'
    },
    addBatch() {
      this.reset()

      this.isEdit = false
      this.showFormBatchModal = true
    },
    add() {
        this.reset()

        this.isEdit = false
        this.showFormModal = true

    },
    editModal(id) {
        this.reset()

        this.isLoadingForm = true

        this.isEdit = true
        this.showFormModal = true

        this.$http.get('/admin/v1/vouchers/' + id)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const currentData = res.data.data
            
              this.currentObject.id = id
              this.currentObject.voucher_name = currentData.voucher_name
              this.currentObject.voucher_code = currentData.voucher_code
              this.currentObject.voucher_category = currentData.voucher_category
              this.currentObject.voucher_value = currentData.voucher_value
            }

            this.isLoadingForm = false
      })

    },
    removeModal(id) {      
      this.showDeleteModal = true

      this.currentObject.id = id
    },
    reset() {
      this.currentObject.voucher_qty = 0
      this.currentObject.voucher_prefix = ''
      this.currentObject.voucher_name = ''
      this.currentObject.voucher_code = ''
      this.currentObject.voucher_category = ''
      this.currentObject.voucher_value = 0
      this.currentObject.id = -1
    },
    remove() {
      this.isLoadingDelete = true

      const id = this.currentObject.id

      this.$http.delete('/admin/v1/vouchers/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sukses`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }

          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.loadItems()
      })
    },
    saveBatch() {
      const requestData = {
        'voucher_name': this.currentObject.voucher_name,
        'voucher_category': this.currentObject.voucher_category,
        'voucher_value': this.currentObject.voucher_value,
        'voucher_qty': this.currentObject.voucher_qty,
        'voucher_prefix': this.currentObject.voucher_prefix,
      }

      this.$http.post('/admin/v1/vouchers_batch', requestData)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sukses`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

            this.loadItems()
          }

          this.isLoadingForm = false
          this.showFormBatchModal = false
      })
    },
    save() {
      const requestData = {
        'voucher_name': this.currentObject.voucher_name,
        'voucher_code': this.currentObject.voucher_code,
        'voucher_category': this.currentObject.voucher_category,
        'voucher_value': this.currentObject.voucher_value,
      }
      
      if(this.isEdit) {
        const id = this.currentObject.id

        this.$http.put('/admin/v1/vouchers/' + id, requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sukses`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

              this.loadItems()
            }

            this.isLoadingForm = false
        })

      }
      else {
        const requestData = {
          'voucher_name': this.currentObject.voucher_name,
          'voucher_code': this.currentObject.voucher_code,
          'voucher_category': this.currentObject.voucher_category,
          'voucher_value': this.currentObject.voucher_value,
        }

        this.$http.post('/admin/v1/vouchers', requestData)
          .then(res => {
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sukses`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

              this.loadItems()
            }

            this.isLoadingForm = false
        })
      }

      this.showFormModal = false
    },
    fetchData(params) {
      this.isLoadingTable = true

      return this.$http.post('/admin/v1/vouchers_filtered', params)
    },
    isValid() {
      return this.currentObject.voucher_name !== null && this.currentObject.voucher_name !== "" &&
              this.currentObject.voucher_code !== null && this.currentObject.voucher_code !== "" &&
              this.currentObject.voucher_value !== null && this.currentObject.voucher_value !== "" &&
              this.currentObject.voucher_value > 0
    },
    isValidBatch() {
      return this.currentObject.voucher_name !== null && this.currentObject.voucher_name !== "" &&
              this.currentObject.voucher_prefix !== null && this.currentObject.voucher_prefix !== "" && this.currentObject.voucher_prefix.length <= 4 &&
              this.currentObject.voucher_value !== null && this.currentObject.voucher_value !== "" &&
              this.currentObject.voucher_value > 0 && this.currentObject.voucher_qty > 0 && this.currentObject.voucher_qty <= 1000
    }
  },
  created() {
    this.loadItems()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
  min-height: 180px;
}

.voucher-filter .v-select.vs--single.vs--searchable {
  width: 280px;
  z-index: 100;
}

.action-button {
  z-index: 100;
}
</style>